@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@font-face {
  font-family: 'Lastica';
  src: local('Lastica'), url(./fonts/Lastica.ttf) format('truetype');
}
html {
  background-color: black;
}
body {
  margin: 0;
  font-family:  HelveticaNeueMedium, sans-serif;
  margin-top: 0 !important;
}

.hero {
  font-family: Lastica;
  background: url(./images/background.png) top center no-repeat; 
  background-size: cover;
 
/*background: linear-gradient(0deg, rgba(0,0,0,1) 35%, rgba(255,255,255,1) 100%);*/

}

li {
  padding:5px;
}
.spacer {
  height:80px;
}
.navbar-header {
  font-family: Lastica;
 position: fixed;
  left: 0px;
  right: 0px;
  top:0px;
  width: 60%;
  max-width: calc(
    100vw - 8px * 2
  );
  height:90px;
  margin-inline: auto;
  border-radius:100px;
  opacity: 70%;
  background-color: black;

}

.header-text {
   font-family: Lastica;
  color: #ff1187 !important;
}

.orange-text {
   color:#ff9c33;
}

.footer {
  background-color:black;
  color:white;

}

.footer a {
    color:white;

}
.footer a:hover {
     color: #ff1187;
}

.cta {
  background-color: black;
}

.black-bg {
  background-color:black;
  color:white;
}

.top-logo {
  width:200px;
}

.text-green-900 {
   color: #ff1187 !important;
}
.bg-green-900 {
  font-family: Lastica;
    background-color: #ff1187 !important;
  }

.border-green-900 {
  border-color: #ff1187 !important;
}

.hover\:text-green-900:hover {
  color: #ff1187 !important;
}

.hover\:border-green-800:hover {
   background-color: #ff1187 !important;
}
